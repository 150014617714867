import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import AuthContext from "../../../AuthContext/AuthContext";

function AddPropertyModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { user } = useContext(AuthContext);

  const [file, setFile] = useState(null);

  const onFinish = (values) => {
    const image = file;
    setLoading(true);
    axios
      .post(
        ` https://samegbackend-production.up.railway.app/api/admin/property/`,
        {
          description: values.description,
          location: values.location,
          price: parseInt(values.price),
          title: values.title,
          size: values.size,
          image: image,
        },

        {
          headers: {
            Authorization: `token ${user}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setLoading(false);
        setIsModalOpen(!isModalOpen);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(true);
        console.log(err.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      console.log(e);
      return e;
    }
    return e?.fileList;
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", file);
    setFile(e.target.files[0]);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add Property
      </Button>

      <Modal
        title="Enter Property Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          enctype="multipart/form-data"
          className="mt-[50px]"
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <p>Title</p>
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Input title!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p>Price</p>
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: "Input title!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <p>Location</p>
          <Form.Item
            name="location"
            rules={[
              {
                required: true,
                message: "Input Location!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p>Size</p>
          <Form.Item
            name="size"
            rules={[
              {
                required: true,
                message: "Input Location!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <p>Description</p>
          <Form.Item name="description">
            <TextArea rows={4} />
          </Form.Item>
          <input type="file" name="file" onChange={handleFileChange} />

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddPropertyModal;
