import React from "react";
import Header from "../components/common/Header";
import Hero from "../components/home/Hero";
import Services from "../components/home/Services";
import Container from "../ui/Container";
import Properties from "../components/home/Properties";
import Target from "../components/home/Target";
import CallToAction from "../components/common/CallToAction";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import PropertiesBox from "../components/home/PropertiesBox";

function Home() {
  return (
    <div>
      <Hero>
        <div className="flex items-center justify-center h-[100%] flex-col flex-wrap bg-[#000000BE]">
          <Container className={"flex flex-col items-center"}>
            <div className="bg-neutral-300 rounded-[20px] px-[10px] py-[3px] flex items-center gap-[10px]">
              <span className="bg-neutral rounded-[20px] py-[3px] px-[10px] lg:text-[14px] text-[12px]">
                Become a partner🏡
              </span>
              <p className="lg:text-[14px] text-[12px]">
                Earn up to 10% commission from every sale as a partner
              </p>
              <Link to="/contact-us">
                <FaArrowRight className="text-[20px]" />
              </Link>
            </div>

            <h1 className="lg:text-[70px] lg:font-[600] font-[600] text-center text-[45px] leading-[50px] lg:leading-[80px] text-neutral-150 mt-[10px]">
              <span className="text-[#0F68D8]  rounded-[10px] ">Invest</span>
              <br className="md:hidden" /> effortlessly{"  "}
              <span className="text-[#0F68D8]  p-[5px] rounded-[10px] ">
                in real <br className="hidden lg:block" />
              </span>
              <span className="text-[#0F68D8]  p-[5px] rounded-[10px] ">
                estate
              </span>
            </h1>
            <p className="mt-[24px] lg:text-[14px] text-[12px] font-[500] text-center drop-shadow-md text-neutral-150">
              Secure your future. Grow wealth
            </p>
            <div className="flex justify-center mt-10 ld:gap-10 gap-[10px]">
              <button className="lg:py-[15px] py-[10px] lg:px-[30px] px-[10px] bg-[#0F68D8] text-[#fff] text-[12px] lg:text-[14px] font-[500] rounded-[10px]">
                Explore Properties
              </button>
              <button className="lg:py-[15px] py-[10px] lg:px-[30px] px-[10px] bg-[#fff] text-[#0F68D8] text-[12px] lg:text-[14px] font-[500] rounded-[10px] border-2 border-[#0F68D8]">
                Call +234 703 878 4788
              </button>
            </div>
          </Container>
        </div>
      </Hero>
      <Services />
      <PropertiesBox />
      <Target />
    </div>
  );
}

export default Home;
