import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../ui/nav/Sidebar";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import Propertiestable from "../../components/tables/Propertiestable";
import AddPropertyToUserModal from "../../components/admin/modal/AddPropertyToUserModal";
import AuthContext from "../../AuthContext/AuthContext";
import axios from "axios";
import { logDOM } from "@testing-library/react";
import MobileAdminSidebar from "../../ui/nav/MobileAdminSidebar";
import UserPropertiestable from "../../components/tables/UserPropertiesTable";
import AdminUserPropertiesTable from "../../components/tables/AdminUserPropertiesTable";

function AdminProfileDetails() {
  const navigate = useNavigate();

  const onFinish = (e) => {
    e.preventDefault();
    console.log("Success:", e);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [properties, setProperties] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);

  const paramValue = urlParams.get("id");

  useEffect(() => {
    const array = [];
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/edit-user/${paramValue}`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/user-properties/${paramValue}/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        data.data.forEach((element) => {
          console.log(element);
          element.property.price = element.property_amount;
          element.property.id = element.id;
          array.push(element.property);
        });
        setProperties(array);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const [componentDisabled, setComponentDisabled] = useState(true);

  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <Sidebar />
      <div className="flex-1 pt-[50px] mx-auto lg:mx-[20px] lg:max-w-[1120px] lg-[90vw] px-[10px]">
        <div className="block pb-[30px] lg:hidden">
          <MobileAdminSidebar />
        </div>
        <div
          className="flex items-center gap-[10px]"
          onClick={() => navigate("/admin/profiles")}
        >
          <IoArrowBack className="text-[30px] font-[400]" />
          <p className="text-[14px] font-[400] text-neutral-350">
            back to profiles
          </p>
        </div>

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" text-[30px]">
            {data?.first_name} {data?.last_name}
          </p>
        </div>

        <div>
          <div className="flex gap-[50px] items-center">
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Profile Details
            </h3>
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            >
              edit Profile
            </Checkbox>

            <AddPropertyToUserModal id={paramValue} />
          </div>

          <form
            className="mt-[50px]"
            name="basic"
            disabled={componentDisabled}
            onSubmit={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="firstName"
              placeholder={data?.first_name}
              rules={[
                {
                  required: true,
                  message: "Input first name!",
                },
              ]}
            >
              <input
                placeholder={data?.first_name}
                value={data?.first_name}
                className="w-[100%] border-[1px] h-[40px] rounded-[10px] px-[10px]"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              placeholder={data?.first_name}
              rules={[
                {
                  required: true,
                  message: "Input Last name!",
                },
              ]}
            >
              <input
                placeholder={data?.last_name}
                value={data?.last_name}
                className="w-[100%] border-[1px] h-[40px] rounded-[10px] px-[10px]"
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Input your username!",
                },
              ]}
            >
              <input
                placeholder={data?.email}
                value={data?.email}
                className="w-[100%] border-[1px] h-[40px] rounded-[10px] px-[10px]"
              />
            </Form.Item>

            <Form.Item>
              {componentDisabled ? (
                ""
              ) : (
                <button
                  type="submit"
                  className="bg-[blue] text-[#ffffff] px-[20px] rounded-[5px] py-[10px]"
                >
                  Submit
                </button>
              )}
            </Form.Item>
          </form>

          <div>
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Acquired Properties
            </h3>
            <AdminUserPropertiesTable
              data={properties}
              location="/admin/properties"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfileDetails;
