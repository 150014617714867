import clsx from "clsx";
import React from "react";

function WelcomeCard({ text, className, first_name }) {
  return (
    <div
      className={clsx(
        "lg:p-[20px] px-[10px] py-[20px] border-[1px] rounded-[5px] border-neutral-250 ",
        className
      )}
    >
      <h1 className="lg:text-[40px] text-[28px]">Welcome 👋🏽,{first_name} </h1>
      <p className="text-neutral-250 font-[400]">{text}</p>
    </div>
  );
}

export default WelcomeCard;
