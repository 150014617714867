import React from "react";

function TargetBox({ title, text }) {
  return (
    <div data-aos="zoom-in" className="p-[20px] rounded-[10px] bg-[#F6F6F6]">
      <p className="font-[700] text-[20px] text-neutral-450">{title} </p>
      <p className="text-[14px] mt-[20px] text-neutral-350">{text}</p>
    </div>
  );
}

export default TargetBox;
