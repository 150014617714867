import React from "react";
import Container from "../../ui/Container";
import TargetBox from "./TargetBox";

function Target() {
  return (
    <div className="">
      <Container className={" lg:border-x-[2px] border-[#F6F6F6] py-[50px]"}>
        <h1
          data-aos="fade-in"
          className="text-center lg:text-[40px] text-[20px] font-[700] "
        >
          WE BRING DREAM HOMES TO REALITY
        </h1>
        <p className="text-center text-[16px] mt-[50px]">
          We have a proven track record of trust earned through our commitment
          to reliability, quality, security and customer satisfaction. Ensuring
          you get the best real estate opportunities is our mission.{" "}
        </p>
        <div className="md:flex-row flex flex-col  justify-between gap-[20px] mt-[100px]">
          <div className="flex-col lg:w-[50%] flex gap-[20px]">
            <TargetBox
              title={"Land banking"}
              text={`What is land banking?
This is simply investing into undeveloped properties for future purposes. You can get upto 50 to 100% ROI on your investment. This is one of the best way of investing in real estate.
We offer you the opportunity to land bank with us at Sameg dreamland properties`}
            />
            <TargetBox
              title={"Fastest documentation process"}
              text={`Our digitized process means less waiting— without unnecessary delays or complications. Our commitment to efficiency means you can focus on what matters most—getting your dream property or investment opportunity—while we handle your documentation fast and accurately.`}
            />
            <TargetBox
              title={"Top-tier Real Estate Investments "}
              text={`Every property listed with Sameg Dreamland Properties undergoes meticulous due diligence with our expert surveyors, and lawyers. Rest assured; we are only bringing the best investment opportunities closer to you.`}
            />
          </div>
          <div className="flex-col flex gap-[20px] lg:w-[50%]">
            <TargetBox
              title={"Real Estate Made Easy for Young People"}
              text={`We break down the real estate process into simple steps, guiding you from start to finish. Taking real estate from boring to exciting—accessible and simplified for young people.`}
            />
            <TargetBox
              title={"Innovative Technology"}
              text={`Every transaction with Sameg Dreamland Properties as a customer can be tracked and monitored via customer dashboards. This is giving every customer first-hand information of their transactions.`}
            />
            <TargetBox
              title={"Regulatory Compliance"}
              text={`At Sameg Dreamland Properties, we pride ourselves on being a fully registered and
        compliant real estate brand with the likes of REDAN, LASERA & EFCC. Our
        adherence to all regulatory standards ensures the utmost protection for
        our clients throughout every transaction.`}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Target;
