import React, { useContext, useState } from "react";

import "../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { login } from "../../utils/dbFunctions";
import AuthContext from "../../AuthContext/AuthContext";

function AdminLogin() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        `https://samegbackend-production.up.railway.app/api/admin/login-admin/`,
        {
          username: e.target.username.value,
          password: e.target.password.value,
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data);
        localStorage.setItem("authToken", data.data.token);
        setUser(data.data.token);
        setLoading(false);
        navigate("/admin/dashboard");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="login-container max-h-[100vh] overflow-hidden">
      <div className="content-wrapper flex items-center">
        <div className="left w-1/2 xsMax:hidden hidden lg:block">
          <div>
            <Link to={"/"}>
              <img className="logo" src={"../images/logo.png"} alt="" />
            </Link>
          </div>
          <div className="flex items-center img-wrpper"></div>
        </div>
        <div className="right">
          <div className="flex items-center justify-center h-[100%]">
            <div className="xsMax:w-[90%] lg:w-[50%] w-[90%]">
              <Link to={"/"}>
                <img
                  className="logo lg:hidden"
                  src={"./images/logo.png"}
                  alt=""
                />
              </Link>

              <h1 className="heading -mt-[60px] lg:-mt-0">Welcome!</h1>
              <p className="text mb-10">Enter details to login.</p>
              <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                  <input placeholder="Username" name="username" />
                </div>
                <div className="input-wrapper">
                  <input
                    name="password"
                    placeholder="Password"
                    type={show ? "text" : "password"}
                  />
                  <p
                    className="form-text cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Hide" : "Show"}
                  </p>
                </div>

                <p className="form-text cursor-pointer">Forgot Password?</p>

                <button
                  type="submit"
                  className="rounded-[5px]"
                  disabled={loading}
                >
                  {loading ? "loading..." : "LOG IN"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
