import React, { useContext, useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import Sidebar from "../../ui/nav/Sidebar";
import PropertyBox from "../../components/admin/PropertyBox";
import WelcomeCard from "../../components/admin/WelcomCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import MobileAdminSidebar from "../../ui/nav/MobileAdminSidebar";

function Dashboard() {
  let headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [owner, setOwners] = useState([]);

  const [properties, setProperties] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setProperties(data.data.properties);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/all-users/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data.data.user);
        setUsers(data.data.user);
        const own = data.data.user.filter(
          (user) => user.is_property_owner === true
        );

        setOwners(own);
        console.log(own);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <Sidebar />
      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] w-[90vw] px-[10px]">
        <div className="block pb-[30px] lg:hidden">
          <MobileAdminSidebar />
        </div>
        <WelcomeCard text={"Here’s a quick update on your dashboard today"} />

        <div className="flex justify-center lg:justify-between mt-[30px] gap-[20px] px-0 mx-0 flex-wrap lg:flex-nowrap">
          <div className="bg-[#EBFDEB] h-[200px] rounded-[10px] w-[90%] lg:w-[30%] flex flex-col items-center justify-center">
            <h2 className="text-[60px] font-[600]">{properties.length}</h2>
            <p>Total Properties</p>
          </div>
          <div className="bg-[#D7E9FF] h-[200px] rounded-[10px]  w-[90%] lg:w-[30%] flex flex-col items-center justify-center">
            <h2 className="text-[60px] font-[600]">{users.length}</h2>
            <p>Total Users</p>
          </div>
          <div className="bg-[#F5EBFE] h-[200px] rounded-[10px]  w-[90%] lg:w-[30%] flex flex-col items-center justify-center px-[20px] text-center">
            <h2 className="text-[60px] font-[600]">0</h2>
            <p>Total Properties purchased</p>
          </div>
          <div className="bg-[#FFF8F5] h-[200px] rounded-[10px]  w-[90%] lg:w-[30%] flex flex-col items-center justify-center px-[20px] text-center">
            <h2 className="text-[60px] font-[600] ">{owner.length}</h2>
            <p>Total Users With Properties</p>
          </div>
        </div>

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" text-[30px]">Recent Properties</p>

          <button onClick={() => navigate("/admin/properties")}>
            View All
          </button>
        </div>

        <div className="flex flex-wrap lg:flex-nowrap gap-[30px] pb-[100px] overflow-scroll">
          {properties.length === 0 ? "No recent property" : null}

          {properties.map((property) => (
            <PropertyBox image={property.image} location={property.location} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
