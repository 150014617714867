import { Popover, Table } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { IoEllipsisVerticalSharp, IoHome, IoTrash } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../AuthContext/AuthContext";
const PopoverBtn = ({ id }) => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleDelete = () => {
    axios
      .delete(
        `https://samegbackend-production.up.railway.app/api/admin/edit-user-property/${id}`,

        {
          headers: {
            Authorization: `token ${user}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log("deleted");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Popover
      content={
        <div className=" flex flex-col gap-2 text-gray-3 px-2">
          <div
            className="flex gap-2 items-center  cursor-pointer hover:translate-y-0.5"
            onClick={() => navigate(`/admin/property/${id}?id=${id}`)}
          >
            <IoHome className="w-3 h-3" />
            <p className=" leading-normal font-normal text-sm">
              View Property details
            </p>
          </div>

          <div
            className="flex gap-2 items-center  cursor-pointer hover:translate-y-0.5"
            onClick={handleDelete}
          >
            <IoTrash className="w-3 h-3 text-[red]" />
            <p className=" leading-normal font-normal text-sm text-[red]">
              Delete
            </p>
          </div>
        </div>
      }
      title=" "
      trigger="click"
    >
      <div className="flex flex-col justify-center items-center text-gray-1 font-bold cursor-pointer">
        <IoEllipsisVerticalSharp className="w-6 h-6 text-grey-1" />
      </div>
    </Popover>
  );
};

function AdminUserPropertiesTable({ data }) {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: " ",
      dataIndex: "",

      render: (key) => <PopoverBtn id={key.id} />,
    },
  ];

  console.log(columns);
  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap gap-[30px] pb-[100px] w-[100%]">
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          className="w-[100%]"
        />
      </div>
    </>
  );
}

export default AdminUserPropertiesTable;
