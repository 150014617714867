import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoGridOutline } from "react-icons/io5";
import { FaLaptopHouse } from "react-icons/fa";
import { CiChat1, CiMenuFries, CiUser } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { BiShield } from "react-icons/bi";
import clsx from "clsx";
import { CgClose } from "react-icons/cg";

function MobileAdminSidebar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleClose = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  return (
    <div>
      <CiMenuFries
        className="text-[20px]"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      />
      <div
        className={clsx(
          " fixed bottom-0 left-0 right-0  top-0 z-40 flex w-[100vw]  min-h-[100vh] max-h-[100vh] flex-col transition  duration-500 ease-in-out bg-[#00000023]",
          showMobileMenu ? "" : "-translate-x-[130vw]"
        )}
      >
        <div className="w-[70vw] bg-[#fff]  pt-[50px] h-[100%]">
          <div className="flex items-center justify-between">
            <img
              src="/images/logo.png"
              alt="logo"
              className="w-[150px] z-10 lg:-top-[60px]  -top-[80px]"
            />

            <CgClose
              className="mr-[30px] text-[30px]"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          </div>

          <div className="flex flex-col gap-[20px] px-[20px] items-between justify-between h-[100%]">
            <div className="flex flex-col gap-[20px] px-[20px] h-[100%]">
              <Link
                to={"/admin/dashboard"}
                className="flex items-center gap-[5px]"
              >
                <IoGridOutline className="text-neutral-400" />
                <p className="text-[14px] font-[500]text-neutral-400">
                  Dashboard
                </p>
              </Link>
              <Link
                to={"/admin/properties"}
                className="flex items-center gap-[5px]"
              >
                <FaLaptopHouse className="text-neutral-300" />
                <p className="text-[14px] font-[500]text-neutral-300">
                  Properties
                </p>
              </Link>
              <Link
                to={"/admin/profiles"}
                className="flex items-center gap-[5px]"
              >
                <CiUser className="text-neutral-400" />
                <p className="text-[14px] font-[500]text-neutral-400">
                  Profiles
                </p>
              </Link>

              <Link className="flex items-center gap-[5px] mt-[auto] mb-[250px]">
                <CiLogout className="text-neutral-400" />
                <p className="text-[14px] font-[500]text-neutral-400">Logout</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileAdminSidebar;
