import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import Propertiestable from "../../components/tables/Propertiestable";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import UserPropertiestable from "../../components/tables/UserPropertiesTable";

function AccountProfileDetails() {
  const navigate = useNavigate();
  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {};

  const [componentDisabled, setComponentDisabled] = useState(true);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/user/get-user/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setData(data.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    const array = [];
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/user/user-property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data.data);
        data.data.data.forEach((element) => {
          element.property.price = element.property_amount;
          element.property.id = element.id;
          array.push(element.property);
        });
        setProperties(array);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <AccountSidebar />

      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw]  lg:px-[10px] px-[24px] flex flex-col ">
        <div className="block pb-[30px] lg:hidden">
          <MobileAccountSidebar />
        </div>
        {/* <WelcomeCard text={"Here’s a quick update on your dashboard today"} /> */}

        <div
          className="flex items-center gap-[10px]"
          onClick={() => navigate("/account/dashboard")}
        >
          <IoArrowBack className="text-[30px] font-[400]" />
          <p className="text-[14px] font-[400] text-neutral-350">
            back to Dashboard
          </p>
        </div>

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" lg:text-[30px] text-[20px]">
            {data?.first_name} {data?.last_name}
          </p>
        </div>

        <div>
          <div className="flex lg:gap-[50px] gap-[10px] items-center">
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Profile Details
            </h3>
            {/* <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            >
              edit Profile
            </Checkbox> */}
          </div>

          <Form
            className="mt-[50px]"
            name="basic"
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            disabled={componentDisabled}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <p>First Name</p>
            <Form.Item
              name="firstName"
              placeholder={data?.first_name}
              rules={[
                {
                  required: true,
                  message: "Input first name!",
                },
              ]}
            >
              <Input placeholder={data?.first_name} />
            </Form.Item>
            <p>Last Name</p>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Input Last name!",
                },
              ]}
            >
              <Input placeholder={data?.last_name} />
            </Form.Item>
            <p>Email</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Input your username!",
                },
              ]}
            >
              <Input placeholder={data?.email} />
            </Form.Item>
            <Form.Item>
              {componentDisabled ? (
                ""
              ) : (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>

          <div>
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Acquired Properties
            </h3>
            <UserPropertiestable
              data={properties}
              location="/account/properties"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountProfileDetails;
