import clsx from "clsx";

const Heading = ({ children, className }) => (
  <h3
    className={clsx(
      "leading-[25.2px]/[180%] text-[0.875rem] font-[700] -tracking-[0.14px]",
      className
    )}
  >
    {children}
  </h3>
);
export default Heading;
