import React from "react";

function Hero({ children }) {
  return (
    <div className="relative overflow-hidden w-[100vw] h-[80vh] lg:h-[calc(100vh -100px)] ">
      <div className="w-[100%] absolute top-0 left-0 h-[100%] lg:h-[calc(100vh -100px)]  bg-[url('./images/hero.jpg')]  bg-no-repeat bg-cover bg-bottom ">
        {children}
      </div>
    </div>
  );
}

export default Hero;
