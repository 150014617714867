import React, { useContext, useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import PropertyBox from "../../components/admin/PropertyBox";
import WelcomeCard from "../../components/admin/WelcomCard";
import { useNavigate } from "react-router-dom";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import { Modal } from "antd";

function AccountDashboard() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);

  const paramValue = urlParams.get("id");

  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/user/get-user/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setData(data.data.data);
        console.log(data.data.data, 0);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/user/user-property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setProperties(data.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setAllProperties(data.data.properties);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <Modal
        open={isModalOpen}
        title="GREAT NEWS!!!😉"
        footer={[]}
        onCancel={handleCancel}
      >
        <p>
          You can become a partner today with SamegDreamlandProperties and earn
          of to 10% commission from each sale terms and conditions applies.
        </p>
        <p className="mt-[50px] font-[500] text-[18px]">
          Contact us now to become a partner.
        </p>
      </Modal>
      <AccountSidebar />

      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw]  lg:px-[10px] px-[24px] flex flex-col ">
        <div className="block pb-[30px] lg:hidden">
          <MobileAccountSidebar />
        </div>
        <WelcomeCard
          text={"Here’s a quick update on your dashboard today"}
          className="max-w-[550px]"
          first_name={data?.first_name}
        />

        <div className="flex mt-[30px] gap-[20px] px-0 mx-0 flex-wrap lg:flex-nowrap">
          <div className="bg-[#EBFDEB] h-[200px] rounded-[10px] w-[100%] lg:w-[30%] flex flex-col items-center justify-center">
            <h2 className="text-[60px] font-[600]">
              {allProperties && allProperties?.length}
            </h2>
            <p>Available Properties</p>
          </div>
          <div className="bg-[#D7E9FF] h-[200px] rounded-[10px]  w-[100%] lg:w-[30%] flex flex-col items-center justify-center">
            <h2 className="text-[60px] font-[600]">{properties.length}</h2>
            <p>Your Properties</p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-[40px] mb-[20px]">
          <p className=" text-[30px]">Recent Properties</p>

          <button onClick={() => navigate("/account/properties")}>
            View All
          </button>
        </div>

        <div className="flex flex-wrap lg:flex-nowrap gap-[30px] pb-[100px]">
          {properties.length === 0 ? "No recent property" : null}

          {properties.map((property) => {
            return (
              <PropertyBox
                image={property.images[0]}
                location={property.property.location}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AccountDashboard;
