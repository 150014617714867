import React, { useContext, useEffect, useState } from "react";
import PropertyBox from "../../components/admin/PropertyBox";
import Sidebar from "../../ui/nav/Sidebar";
import Propertiestable from "../../components/tables/Propertiestable";
import WelcomeCard from "../../components/admin/WelcomCard";
import { Space } from "antd";
import AddPropertyModal from "../../components/admin/modal/AddProprtyModal";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import { useParams } from "react-router-dom";
import UserPropertiestable from "../../components/tables/UserPropertiesTable";
import MobileAdminSidebar from "../../ui/nav/MobileAdminSidebar";

function AdminProperties() {
  const [data, setData] = useState(null);
  const { user } = useContext(AuthContext);
  const { paramValue } = useParams();

  console.log(paramValue, 9);

  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data.data);
        setData(data.data.properties);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="min-h-[100vh] bg-[#fff] flex gap-[50px] ">
      <Sidebar />
      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw] px-[10px]">
        <div className="block pb-[30px] lg:hidden">
          <MobileAdminSidebar />
        </div>
        <WelcomeCard
          text={"Here’s a quick update on your available properties"}
        />

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" text-[30px]">Properties</p>
        </div>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <AddPropertyModal />
        </Space>
        <Propertiestable data={data} />
      </div>
    </div>
  );
}

export default AdminProperties;
