import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";

function PropertyBox({ image, location }) {
  return (
    <div className="w-[100%] lg:w-[calc(100%/3)] rounded-[10px] border-[1px] flex-none">
      <div
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
        className={` bg-no-repeat bg-cover bg-center  rounded-[10px] overflow-hidden w-[100%] h-[300px]`}
      >
        {/* <img src={image} className="w-[100%] h-[300px]" alt="" /> */}
      </div>
      <div className="p-[20px]">
        <p className="text-[20px] font-[500]">{location}</p>
        <div className="flex items-center">
          <CiLocationArrow1 />
          <p className="text-[14px] text-neutral-350">{location},</p>
        </div>
      </div>
    </div>
  );
}

export default PropertyBox;
