import clsx from "clsx";
import React from "react";

function SmallText({ className, children }) {
  return (
    <p
      data-aos="fade-in"
      data-aos-delay="500"
      className={clsx("lg:text-[1rem] text-[0.825rem] font-[400]", className)}
    >
      {children}
    </p>
  );
}

export default SmallText;
