import React, { useEffect } from "react";
import Container from "../../ui/Container";
import AOS from "aos";
import "aos/dist/aos.css";

function Services() {
  return (
    <div className="py-[100px] bg-[#F6F6F6]">
      <Container className={"px-[20px]"}>
        <h1
          data-aos="fade-in"
          className="text-center lg:text-[40px] text-[20px] font-[700]"
        >
          Explore Our Categories & Places
        </h1>
        <p className="text-center text-[14px] mt-[50px]">
          We understand how hard it is to purchase a property, which is why we
          provide you with the best-in-class property deal.
        </p>

        <div className="flex flex-wrap gap-[15px] lg:gap-[30px] justify-center mt-[40px] ">
          <div
            data-aos="zoom-in"
            className="drop-shadow-md flex flex-col items-center p-[20px] bg-[#F6F6F6] rounded-[10px] lg:w-[200px] w-[calc(50%-30px)]"
          >
            <img src="/images/home/building.png" className="max-w-[80px]" />
            <p className="text-center mt-[10px] text-[14px] font-[500]">
              Invest in Real Estate
            </p>
          </div>
          <div
            d
            data-aos="zoom-in"
            class="drop-shadow-md flex flex-col items-center p-[20px] bg-[#F6F6F6] rounded-[10px] lg:w-[200px] w-[calc(50%-30px)]"
          >
            <img src="/images/home/investment.png" className="max-w-[80px]" />
            <p className="text-center mt-[10px] text-[14px] font-[500]">
              Buy a Land
            </p>
          </div>
          <div
            data-aos="zoom-in"
            class="drop-shadow-md flex flex-col items-center p-[20px] bg-[#F6F6F6] rounded-[10px] lg:w-[200px] w-[calc(50%-30px)]"
          >
            <img src="/images/home/search.png" className="max-w-[80px]" />
            <p className="text-center mt-[10px] text-[14px] font-[500]">
              Buy a House
            </p>
          </div>
          <div
            data-aos="zoom-in"
            class="drop-shadow-md flex flex-col items-center p-[20px] bg-[#F6F6F6] rounded-[10px] lg:w-[200px] w-[calc(50%-30px)]"
          >
            <img src="/images/home/agreement .png" className="max-w-[80px]" />
            <p className="text-center mt-[10px] text-[14px] font-[500]">
              Land Banking
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Services;
