import React, { useContext, useEffect, useState } from "react";
import Propertiestable from "../../components/tables/Propertiestable";
import WelcomeCard from "../../components/admin/WelcomCard";
import { Space } from "antd";
import axios from "axios";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";
import AuthContext from "../../AuthContext/AuthContext";
import UserPropertiestable from "../../components/tables/UserPropertiesTable";

function AccountProperties() {
  const [data, setData] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const array = [];
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/user/user-property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data.data);
        data.data.data.forEach((element) => {
          console.log(element);
          element.property.price = element.property_amount;
          array.push(element.property);
        });
        setData(array);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="min-h-[100vh] bg-[#fff] flex gap-[50px] ">
      <AccountSidebar />
      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw] px-[10px]">
        <div className="block pb-[30px] lg:hidden">
          <MobileAccountSidebar />
        </div>
        <WelcomeCard text={"Here’s a quick update on your properties"} />

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" text-[30px]">Properties</p>
        </div>
        <Space
          style={{
            marginBottom: 16,
          }}
        ></Space>
        <UserPropertiestable data={data} />
      </div>
    </div>
  );
}

export default AccountProperties;
