import React from "react";
import Container from "../../ui/Container";
import Button from "../../ui/Button";
import SmallText from "../../ui/SmallText";
import SubHeading from "../../ui/SubHeading";
import { Link } from "react-router-dom";
import Counter from "../../ui/Counter";

function PropertiesBox() {
  return (
    <div className="lg:pt-[108px] lg:pb-[156px] pb-[100px] pt-[34px] pb-[100px] bg-[#05264F] lg:pb-[208px] bg-[url('./images/home/grid-layer.png')] ">
      <div></div>
      <Container className=" lg:flex gap-[108px] ">
        <div className="lg:w-[50%] flex flex-col gap-[24px] text-[#fff]">
          <Link
            to="/about-us"
            className=" py-[10px] rounded-[56px] max-w-[200px] flex justify-center w-[160px] border-[1px] border-neutral py-[10px]"
          >
            <SmallText className="text-[#fff]">What we offer</SmallText>
          </Link>
          {/* <Button
            text="For employer"
            className=" border-[2px] border-primary py-[10px]"
          /> */}

          <div className="max-w-[495px] flex flex-col gap-[24px]">
            <SubHeading>Looking for your dream property?</SubHeading>
            <SmallText>
              Finding your dream home is more than just a transaction—it's about
              finding the perfect space where your life unfolds. At
              Samegdreamland properties, we understand the significance of this
              journey, and we're here to guide you every step of the way.
            </SmallText>
          </div>

          <div className="flex gap-[10px] mt-[40px] mb-[31px]">
            <Button text="View Properties" primary={true} />
            <Link
              to="/contact-us"
              className=" py-[10px] rounded-[56px] max-w-[200px] flex justify-center w-[160px] py-[10px] flex items-center gap-[10px]"
            >
              <SmallText className="text-[#fff]">Learn more</SmallText>
              <img src="./images/icon.svg" alt="" />
            </Link>
          </div>
        </div>

        <div className="lg:w-[50%]">
          <div
            className="lg:h-[540px] h-[350px]"
            data-aos="zoom-in"
            data-aos-offset="500"
          >
            <div className="relative lg:h-[507px] h-[282px] bg-neutral lg:w-[334px] w-[240px] rounded-[8px]">
              <img
                src="/images/propert.png"
                alt=""
                className="lg:h-[615px] lg:w-[734px] h-[286px] w-[400px] z-[10]"
              />
              <div className="text-center text-[#ffffff] absolute z-[10] bg-[#071544DA] lg:gap-[31px] flex px-[32px] py-[30px] rounded-[8px] lg:bottom-[44px] bottom-[14px] lg:left-[100px] gap-[10px] max-w-[504px] left-[50px]">
                <div>
                  <p className="font-[700] lg:text-[2.794rem] text-[1.5rem]">
                    <Counter number={100} />+
                  </p>
                  <p className="lg:text-[1.125rem] text-[10px] font-[600] text-nowrap ">
                    Available Properties
                  </p>
                </div>
                <div>
                  <p className="font-[700] lg:text-[2.794rem] text-[1.5rem]">
                    <Counter number={100} />%
                  </p>
                  <p className="lg:text-[1.125rem] text-[10px] font-[600] text-nowrap ">
                    Delivery Rate
                  </p>
                </div>
                <div>
                  <p className="font-[700] lg:text-[2.794rem] text-[1.5rem]">
                    <Counter number={1} />
                  </p>
                  <p className="lg:text-[1.125rem] text-[10px] font-[600] text-nowrap ">
                    Countries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PropertiesBox;
