import React from "react";
import Container from "../ui/Container";
import ContactCard from "../components/contact/ContactCard";

function Contact() {
  return (
    <div>
      <div className="bg-[url('./images/hero.jpg')] w-[100vw] h-[50vh] bg-no-repeat bg-cover bg-bottom ">
        <div className="bg-[#0F69D860] h-[100%] flex flex-col gap-[24px] items-center justify-center">
          <p className="text-center text-[#fff]">HOME / CONTACT</p>
          <p className="text-[30px] font-[700] text-[#fff]">CONTACT US</p>
        </div>
      </div>
      <Container className={"mt-[100px]"}>
        <h1 className="lg:text-[60px] text-center text-[30px] pb-[40px]">
          Someone Is Always Ready To Talk To You
        </h1>
        <div className="flex flex-col lg:flex-row justify-center gap-[20px] pb-[50px]">
          <ContactCard
            subText="Speak to our friendly team."
            text="hello@samegdreamlandproperties.com"
            contact=" Send us a Mail"
            className={"bg-[url('./images/contact/Chat.png')]"}
          />
          <ContactCard
            subText="Speak to our friendly team."
            contact="+234 703 878 4788"
            text="Put a Call Through"
            className={"bg-[url('./images/contact/phone.png')]"}
          />
          <ContactCard
            subText="Speak to our friendly team."
            contact="Click to talk to Support"
            text="Talk to Support"
            className={"bg-[url('./images/contact/Message.png')]"}
          />
        </div>
      </Container>
    </div>
  );
}

export default Contact;
