import React from "react";
import Container from "../../ui/Container";
import MobileNav from "../../ui/nav/MobileNav";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="border-b-2">
      <Container className={"flex py-[30px] justify-between"}>
        <div></div>
        <Link to={"/"} className="relative w-[200px] h-[70]">
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-[200px] absolute z-10 lg:-top-[60px]  -top-[80px]"
          />
        </Link>

        <div className="lg:flex gap-[30px] items-center hidden">
          <Link>
            <p className="font-[400] text-[14px]">Our Properties</p>
          </Link>
          <Link to={"/about-us"}>
            <p className="font-[400] text-[14px]">About Us</p>
          </Link>
          <Link to={"/services"}>
            <p className="font-[400] text-[14px]">Services</p>
          </Link>
          <Link to={"/contact-us"}>
            <p className="font-[400] text-[14px]">Contact Us</p>
          </Link>
          <Link to={"/login"}>
            <p className="font-[400] text-[14px] border-[2px] py-[10px] px-[20px] rounded-[30px]">
              Login
            </p>
          </Link>
        </div>

        <div className="block lg:hidden">
          <MobileNav />
        </div>
      </Container>
    </div>
  );
}

export default Header;
