import clsx from "clsx";
import React from "react";

function SubHeading({ className, children }) {
  return (
    <h2
      data-aos="fade-in"
      data-aos-delay="500"
      className={clsx(
        "lg:text-[2.5rem] text-[1.25rem] font-[700] leading-[22px] lg:leading-[44px]",
        className
      )}
    >
      {children}
    </h2>
  );
}

export default SubHeading;
