import React from "react";
import Container from "../../ui/Container";

function CallToAction() {
  return (
    <Container
      className={
        "bg-[url('./images/action.webp')] bg-cover bg-no-repeat lg:px-0 rounded-[20px] overflow-hidden"
      }
    >
      <div className="bg-[#0000004F] w-[100%] h-[580px] flex justify-center items-center ">
        <div className="bg-[#ffffff] rounded-[10px] lg:max-w-[70%] w-[90%]">
          <p className="lg:font-[500] lg:text-[25px] font-[500] text-[20px] text-center py-[30px] px-[20px] text-neutral-350">
            Enjoy your real estate investment journey with SamegDLProperties
          </p>
          <div className="flex lg:flex-row flex-col items-center justify-center pb-[40px] mt-[10px] lg:gap-10 gap-[10px]">
            <button className="lg:py-[15px] py-[10px] lg:px-[30px] px-[10px] bg-[#0F68D8] text-[#fff] text-[12px] lg:text-[14px] font-[500] rounded-[10px] text-neutral-250">
              Explore Properties
            </button>
            <button className="lg:py-[15px] py-[10px] lg:px-[30px] px-[10px] bg-[#fff] text-[#0F68D8] text-[12px] lg:text-[14px] font-[500] rounded-[10px] border-2 border-[#0F68D8]">
              Call +234 703 878 4788
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CallToAction;
