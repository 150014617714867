import clsx from "clsx";
import React from "react";
import SmallText from "./SmallText";

function Button({ className, text, primary }) {
  return (
    <button
      className={clsx(
        " py-[10px] rounded-[56px] max-w-[200px] flex justify-center w-[160px] ",
        className,
        primary ? "bg-[#0F68D8] " : "bg-[transparent]"
      )}
    >
      <SmallText className={clsx(primary ? "text-[#fff]" : "text-[#0F68D8]")}>
        {text}
      </SmallText>
    </button>
  );
}

export default Button;
