import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Container from "../ui/Container";
import AboutCard from "../components/about/AboutCard";

function Services() {
  return (
    <div>
      <div className="bg-[url('./images/hero.jpg')] w-[100vw] h-[50vh] bg-no-repeat bg-cover bg-bottom ">
        <div className="bg-[#0F69D860] h-[100%] flex flex-col gap-[24px] items-center justify-center">
          <p className="text-center text-[#fff]">HOME / SERVICES</p>
          <p className="text-[30px] font-[700] text-[#fff]">SERVICES</p>
        </div>
      </div>
      <Container className="pb-[100px]">
        <h1 className="lg:text-[60px] text-center text-[30px] py-[40px]">
          What we Offer
        </h1>
        <div>
          <AboutCard
            title={
              "Introducing Sameg Dreamland Properties: Redefining Real Estate Excellence"
            }
            text={
              "At Sameg Dreamland Properties, we don't just sell properties; we craft dreams into reality. As a premier real estate company, we are dedicated to providing unparalleled service and expertise to our clients, whether they are buying, selling, or investing in real estate."
            }
          />
          <AboutCard
            title={"Our Commitment to Excellence"}
            text={
              "At the heart of our ethos lies an unwavering commitment to excellence. We understand that buying or selling a property is one of the most significant financial decisions an individual can make. That's why we go above and beyond to ensure that every transaction is smooth, transparent, and tailored to meet the unique needs of our clients."
            }
          />
          <AboutCard
            title={"Expert Guidance Every Step of the Way"}
            text={
              "Navigating the real estate market can be daunting, but with Sameg Dreamland Properties by your side, you can rest assured that you're in capable hands. Our team of seasoned real estate professionals boasts extensive knowledge of the local market trends, neighborhoods, and legal intricacies. Whether you're a first-time homebuyer, a seasoned investor, or a seller looking to maximize your property's value, we provide expert guidance and personalized solutions to help you achieve your goals."
            }
          />
          <AboutCard
            title={"Innovative Marketing Strategies"}
            text={
              "In today's digital age, effective marketing is key to success in real estate. At Sameg Dreamland Properties, we leverage the latest technology and marketing tools to showcase properties in the best possible light. From professional photography and virtual tours to targeted online advertising and social media campaigns, we ensure that your property receives maximum exposure to potential buyers."
            }
          />
          <AboutCard
            title={"A Seamless Experience"}
            text={
              "At Sameg Dreamland Properties, we understand that real estate transactions can be complex and time-consuming. That's why we strive to make the process as seamless and stress-free as possible for our clients. From initial consultation to closing, we handle every aspect of the transaction with professionalism, integrity, and attention to detail."
            }
          />
          <AboutCard
            title={"Your Trusted Partner in Real Estate"}
            text={
              "Whether you're buying, selling, or investing in real estate, Sameg Dreamland Properties is your trusted partner every step of the way. With our unwavering commitment to excellence, expert guidance, and innovative approach, we are redefining the real estate experience for clients across the region."
            }
          />
        </div>
      </Container>
    </div>
  );
}

export default Services;
