import React from "react";
import Text from "../../ui/footer/Text";
import Heading from "../../ui/footer/Heading";

function Footer() {
  return (
    <div className="py-[100px]">
      <div className="flex flex-wrap justify-center px-[24px] ">
        <div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="flex flex-col gap-[18px]">
              {/* <LogoBox /> */}
              <Text className="md:mr-[113px] md:w-[268px]">
                The organization oversees assets and real estate investment
                opportunities, offering clients worldwide access upon request
                via direct purchase or through trusted partners.
              </Text>
            </div>
            <div className="mt-[56px] flex w-[100%] flex-wrap md:mt-0 md:gap-[20px]">
              <div className="flex w-[50%] flex-col gap-[32px] md:w-[154px]">
                <Heading>PRODUCTS</Heading>
                <div className="flex flex-col gap-[16px]">
                  <a href="#">
                    <Text>Properties</Text>
                  </a>

                  <a href="/services">
                    <Text>Services</Text>
                  </a>
                </div>
              </div>
              <div className="flex w-[50%] flex-col gap-[32px] md:w-[154px]">
                <Heading>COMPANY</Heading>
                <div className="flex flex-col gap-[16px]">
                  <a href="/about-us">
                    <Text>About Us</Text>
                  </a>
                  <a href="#">
                    <Text>Privacy Policy</Text>
                  </a>
                  <a href="#">
                    <Text>Terms</Text>
                  </a>
                </div>
              </div>
              <div className="mt-[48px] flex w-[50%] flex-col gap-[32px] md:mt-0 md:w-[154px]">
                <Heading>SOCIAL MEDIA</Heading>
                <div className="flex flex-col gap-[16px]">
                  <a href="#" target="_blank">
                    <Text>Facebook</Text>
                  </a>
                  <a href="#" target="_blank">
                    <Text>Instagram</Text>
                  </a>
                </div>
              </div>
              <div className="mt-[48px] flex w-[50%] flex-col gap-[32px] md:mt-0 md:w-[154px]">
                <Heading>SUPPORT</Heading>
                <div className="flex flex-col gap-[16px]">
                  <a href="/contact-us">
                    <Text>Contact Us</Text>
                  </a>
                  <a href="">
                    <Text>Faqs</Text>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 flex flex-wrap gap-[32px]">
            <div className="w-[100%] rounded-[16px] bg-neutral-100 pb-[40px] pl-[20px] pt-[32px] md:w-auto md:pb-[50px] md:pl-[24px] md:pr-[57px] md:pt-[35px] lg:flex-none">
              <p className="max-w-[333px] text-[0.75rem] leading-[200%] -tracking-[0.12px] text-neutral-350 md:max-w-[826px] md:text-[1rem] md:-tracking-[0.16px]">
                Sameg Dreamland Properties (“Sameg DL Properties”) is a
                real estate company duly registered in Lagos-Nigeria and by the
                Corporate Affairs Commission, Nigeria (RC:7354184). It is
                licensed by the Lagos State Real Estate Regulatory Authority
                (LASERA) and duly registered with the Real Estate Developer
                Association of Nigeria (REDAN) and the Economic and Financial
                Crimes Commission via its Special Control Unit Money Laundering
                (SCUML) in accordance with the provisions of section(17)(a) of
                the money laundering (Prevention and prohibition) Act, 2022 and
                any other law or regulation.
              </p>
            </div>
            <div className="hidden flex-none lg:block">
              <img src="/images/logo.png" height="180" width="180" alt="" />
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-[67px] bg-neutral-200" />
    </div>
  );
}

export default Footer;
