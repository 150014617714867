import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import AuthContext from "../../../AuthContext/AuthContext";

function AddPropertyToUserModal({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [selected, setSelected] = useState(null);
  const { user } = useContext(AuthContext);
  const [properties, setProperties] = useState([]);

  const handleAddProperty = (e) => {
    e.preventDefault();
    console.log(e.target.size.value);
    const image = file;

    console.log(selected, id);
    axios
      .post(
        "https://samegbackend-production.up.railway.app/api/admin/create-user-property/",
        {
          user_id: id,
          property_id: selected,
          property_amount: parseInt(e.target.amount.value),
          paid_amount: parseInt(e.target.paid.value),
          size_bought: parseInt(e.target.amount.size),
          images: [image],
        },
        {
          headers: {
            Authorization: `token ${user}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", file);
    setFile(e.target.files[0]);
  };

  const handleSelectChange = (e) => {
    setSelected(e.target.value);
  };

  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/property/`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setProperties(data.data.properties);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div>
      <Modal
        title="Enter Property Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form
          className="mt-[50px]"
          name="basic"
          style={{
            maxWidth: 600,
          }}
          onSubmit={handleAddProperty}
          autoComplete="off"
        >
          <div>
            <select
              className="mb-[20px] border-[1px] p-[10px] rounded-[10px] "
              onChange={handleSelectChange}
            >
              <option>Select Property</option>
              {properties?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <p>Size</p>
          <Form.Item
            name="Size"
            rules={[
              {
                required: true,
                message: "Input title!",
              },
            ]}
          >
            <Input name="size" />
          </Form.Item>
          <p>Property Amount</p>
          <Form.Item
            name="Amount"
            rules={[
              {
                required: true,
                message: "Input title!",
              },
            ]}
          >
            <Input name="amount" />
          </Form.Item>
          <p>Amount Paid</p>
          <Form.Item
            name="Paid"
            rules={[
              {
                required: true,
                message: "Input title!",
              },
            ]}
          >
            <Input name="paid" />
          </Form.Item>

          <p>Add Image</p>
          <input type="file" name="file" onChange={handleFileChange} />
          <Form.Item className="mt-[10px]">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </form>
      </Modal>
      <Button onClick={() => setIsModalOpen(!isModalOpen)}>Add Property</Button>
    </div>
  );
}

export default AddPropertyToUserModal;
