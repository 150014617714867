import React, { useContext, useEffect, useState } from "react";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";
import { IoArrowBack } from "react-icons/io5";
import PropertyDetailsBox from "../../components/admin/PropertyDetailsBox";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import Sidebar from "../../ui/nav/Sidebar";
import MobileAdminSidebar from "../../ui/nav/MobileAdminSidebar";

function AdminUserProprtyDetails() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const paramValue = urlParams.get("id");
  const [data, setData] = useState(null);
  const [property, setProperty] = useState({});

  const { user } = useContext(AuthContext);
  useEffect(() => {
    let propertyDetails = {};
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/edit-user-property/${paramValue}`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setData(data.data);
        propertyDetails.paid_amount = data.data.paid_amount;
        propertyDetails.property_amount = data.data.property_amount;
        propertyDetails.size_bought = data.data.size_bought;
        propertyDetails.image = data.data.images[0];
        propertyDetails.description = data.data.property.description;
        propertyDetails.location = data.data.property.location;

        console.log(data.data, propertyDetails);
        setProperty(propertyDetails);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div>
      <div className="bg-[#fff] flex gap-[50px] ">
        <Sidebar />
        <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw] px-[10px]">
          <div className="block pb-[30px] lg:hidden">
            <MobileAdminSidebar />
          </div>
          {/* <WelcomeCard text={"Here’s a quick update on your dashboard today"} /> */}

          <div
            className="flex items-center gap-[10px]"
            onClick={() => navigate("/admin/properties")}
          >
            <IoArrowBack className="text-[30px] font-[400]" />
            <p className="text-[14px] font-[400] text-neutral-350">
              back to properties
            </p>
          </div>

          <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
            <p className=" text-[30px]">{data?.title}</p>
          </div>

          <div className="flex  lg:flex-nowrap gap-[30px] pb-[20px] overflow-scroll">
            <PropertyDetailsBox
              image={property?.image}
              location={property?.location}
            />
          </div>
          <div>
            <h3 className="font-[600]">Size</h3>
            {property?.size_bought}
            <h3 className="font-[600]">Property Price</h3>
            <p> ₦{property?.paid_amount}</p>
            <h3 className="font-[600]">Amount Paid</h3>
            <p>₦{property?.property_amount}</p>
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Description
            </h3>
            <p className="text-[14px] text-neutral-400">
              {property?.description}
            </p>
            {/* <h3 className="text-[20px] text-neutral-700 font-[500] mt-[10px]">
            Price
          </h3>
          <p className="text-[14px] text-neutral-400">#1000,000</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUserProprtyDetails;
