import React from "react";
import Heading from "../../ui/Heading";
import Text from "../../ui/Text";

function AboutCard({ title, text }) {
  return (
    <div className="flex flex-col gap-[18px] mt-[50px]">
      <Heading className={""}>{title}</Heading>
      <Text>{text}</Text>
    </div>
  );
}

export default AboutCard;
