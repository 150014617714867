import React from "react";
import AboutCard from "../components/about/AboutCard";
import Container from "../ui/Container";
import Text from "../ui/Text";

function About() {
  return (
    <div>
      <div className="bg-[url('./images/hero.jpg')] w-[100vw] h-[50vh] bg-no-repeat bg-cover bg-bottom ">
        <div className="bg-[#0F69D860] h-[100%] flex flex-col gap-[24px] items-center justify-center">
          <p className="text-center text-[#fff]">HOME / ABOUT</p>
          <p className="text-[30px] font-[700] text-[#fff]">ABOUT US</p>
        </div>
      </div>
      <Container className={"mt-[100px]"}>
        <h1 className="lg:text-[60px] text-center text-[30px] pb-[40px]">
          Digitizing Real Estate investments for Africans
        </h1>

        <p className="">
          We are building a real estate investment culture while customizing its
          rigid traditional set-up and bridging the cultural age gap, so that
          young adults can build wealth in their prime as the population
          continues to grow.
        </p>

        <div className="pb-[50px]">
          <AboutCard
            title={"Property Listings and Search"}
            text={
              " Our real estate website services offer comprehensive property listings and robust search functionality, allowing users to find their dream properties effortlessly. With advanced filters, users can narrow down their search based on location, price range, property type, amenities, and more. Our intuitive interface ensures a seamless browsing experience, whether users are searching for residential, commercial, or rental properties."
            }
          />
          <AboutCard
            title={"Virtual Tours and Multimedia Content"}
            text={
              "We understand the importance of visual representation in the real estate industry. That's why our platform incorporates high-quality images, virtual tours, and multimedia content to showcase properties in the best possible light. Through immersive experiences, prospective buyers and tenants can explore properties remotely, gaining a better understanding of layout, design, and features before scheduling an in-person visit."
            }
          />
          <AboutCard
            title={"Customized Property Alerts"}
            text={
              "Stay ahead of the competition with our personalized property alert system. Users can set their preferences and receive notifications whenever new listings that match their criteria become available. Whether it's a specific neighborhood, property type, or price range, our alert system ensures that users never miss out on relevant opportunities in the dynamic real estate market."
            }
          />
          <AboutCard
            title={"Market Insights and Analytics"}
            text={
              "Gain valuable insights into local real estate trends and market dynamics with our comprehensive analytics tools. From property price trends to neighborhood statistics, our platform provides data-driven insights to empower informed decision-making. Whether you're a buyer, seller, or investor, access to timely and accurate market information is essential for maximizing your real estate investments."
            }
          />
          <AboutCard
            title={"Secure Transactions and Documentation"}
            text={
              "Our real estate website services prioritize security and transparency throughout the transaction process. From initial inquiries to closing deals, our platform facilitates secure communication between buyers, sellers, agents, and other stakeholders. Additionally, our integrated document management system streamlines paperwork, ensuring that all transactions are conducted efficiently and in compliance with legal requirements."
            }
          />
          <AboutCard
            title={"Mobile Accessibility and Responsive Design"}
            text={
              "Access our real estate website services anytime, anywhere, with our mobile-responsive design. Whether you're using a smartphone, tablet, or desktop computer, our platform adapts seamlessly to any screen size or device type. Stay connected and engaged with the real estate market on the go, whether you're browsing listings, scheduling viewings, or communicating with agents."
            }
          />
          <AboutCard
            title={"Community and Neighborhood Information"}
            text={
              "Beyond just property listings, our platform provides comprehensive information about communities and neighborhoods. From school districts to local amenities, users can explore the factors that influence their decision to buy or rent a property in a particular area. Whether it's safety ratings, transportation options, or cultural attractions, our platform equips users with the knowledge they need to make informed decisions about their future homes."
            }
          />

          <Text>
            By offering these essential features and services, our real estate
            website platform aims to simplify the property search process,
            foster transparency and trust, and empower users to make confident
            real estate decisions. Whether you're a first-time buyer, seasoned
            investor, or property professional, our platform has something to
            offer for everyone in the dynamic world of real estate.
          </Text>
        </div>
      </Container>
    </div>
  );
}

export default About;
