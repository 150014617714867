import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./pages/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Contact from "./pages/Contact";
import Properties from "./pages/Properties";
import Services from "./pages/Services";
import About from "./pages/About";
import Login from "./pages/Login";
import AOS from "aos";
import Dashboard from "./pages/admin/Dashboard";
import AdminProperties from "./pages/admin/AdminProperties";
import AdminProfiles from "./pages/admin/AdminProfiles";
import PropertyDetails from "./pages/admin/PropertyDetails";
import AdminProfileDetails from "./pages/admin/AdminProfileDetails";
import AdminLogin from "./pages/admin/Adminlogin";
import { AuthProvider } from "./AuthContext/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";
import AccountProperties from "./pages/account/AccountProperties";
import AccountProfileDetails from "./pages/account/AdminProfileDetails";
import AccountSupport from "./pages/account/AccountSupport";
import AccountSecurity from "./pages/account/AccountSecurity";
import AccountDashboard from "./pages/account/AccountDashboard";
import AccountPropertiesDetails from "./pages/account/AccountPropertyDetails";
import UserProprtyDetails from "./pages/account/UserProprtyDetails";
import AdminUserProprtyDetails from "./pages/admin/AdminUserPropertDetails";

// Initialize AOS
AOS.init({
  offset: 300,
  easing: "ease-out-quad",
  duration: 1000,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <Home /> },
      { path: "/contact-us", element: <Contact /> },
      { path: "/properties", element: <Properties /> },
      { path: "/about-us", element: <About /> },
      {
        path: "/services",
        element: <Services />,
      },
    ],
  },

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/admin/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/admin/properties",
        element: <AdminProperties />,
      },
      {
        path: "/admin/properties/:id",
        element: <PropertyDetails />,
      },
      {
        path: "/admin/profiles",
        element: <AdminProfiles />,
      },
      {
        path: "/admin/profiles/:id",
        element: <AdminProfileDetails />,
      },
      {
        path: "/admin/property/:id",
        element: <AdminUserProprtyDetails />,
      },
    ],
  },
  {
    path: "/account",
    element: <PrivateRoute />,
    children: [
      {
        path: "/account/dashboard",
        element: <AccountDashboard />,
      },
      {
        path: "/account/properties",
        element: <AccountProperties />,
      },

      {
        path: "/account/properties/:id",
        element: <AccountPropertiesDetails />,
      },
      {
        path: "/account/profile",
        element: <AccountProfileDetails />,
      },
      {
        path: "/account/support",
        element: <AccountSupport />,
      },
      {
        path: "/account/security",
        element: <AccountSecurity />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
