import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";

function PropertyDetailsBox({ image, location }) {
  return (
    <div className="w-[90%] lg:w-[calc(100%)] rounded-[10px] border-[1px] flex-none">
      <div
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
        className="  h-[500px] bg-no-repeat bg-cover bg-center  rounded-[10px] overflow-hidden w-[100%] h-[300px]  "
      ></div>
      <div className="p-[20px]">
        {/* <p className="text-[20px] font-[500]">Panasia House</p> */}
        <div className="flex items-center">
          <CiLocationArrow1 />
          <p className="text-[14px] text-neutral-350">{location}</p>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsBox;
