import clsx from "clsx";
import React from "react";

function Heading({ className, children }) {
  return (
    <p className={clsx("font-[700] text-[20px] text-[gray]", className)}>
      {children}
    </p>
  );
}

export default Heading;
