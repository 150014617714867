import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import Propertiestable from "../../components/tables/Propertiestable";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";

function AccountSecurity() {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [componentDisabled, setComponentDisabled] = useState(true);

  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <AccountSidebar />

      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw]  lg:px-[10px] px-[24px] flex flex-col ">
        <div className="block pb-[30px] lg:hidden">
          <MobileAccountSidebar />
        </div>
        {/* <WelcomeCard text={"Here’s a quick update on your dashboard today"} /> */}

        <div
          className="flex items-center gap-[10px]"
          onClick={() => navigate("/account/dashboard")}
        >
          <IoArrowBack className="text-[30px] font-[400]" />
          <p className="text-[14px] font-[400] text-neutral-350">
            back to Dashboard
          </p>
        </div>

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          {/* <p className=" lg:text-[30px] text-[20px]">Oghomena Onojuvwevwo</p> */}
        </div>

        <div>
          <div className="flex lg:gap-[50px] items-center">
            <h3 className="text-[20px] text-neutral-700 font-[500]">
              Change Password
            </h3>
          </div>

          <Form
            className="mt-[50px]"
            name="basic"
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div>
              <p> Old Password</p>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Input first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <p> Password</p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Input Last name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <p>Confirm Password</p>
              <Form.Item
                d
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="py-[20px] flex items-center"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AccountSecurity;
