import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import axios from "axios";

function AddUserModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    console.log(
      values.firstName,
      values.lastName,
      values.email,
      values.password
    );
    axios
      .post(`https://samegbackend-production.up.railway.app/api/signup/`, {
        first_name: values.firstName,
        last_name: values.lastLame,
        email: values.email.charAt(0).toUpperCase() + values.email.slice(1),
        password: values.password,
      })
      .then((res) => {
        return res;
      })
      .then((data) => {
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `https://samegbackend-production.up.railway.app/api/user/signup/`,

        {
          first_name: e.target.first_name.value,
          last_name: e.target.last_name.value,
          email: e.target.email.value,
          password: e.target.password.value,
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log(data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add User
      </Button>

      <Modal
        title="Enter User Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form
          className="mt-[50px]"
          name="basic"
          style={{
            maxWidth: 600,
          }}
          onSubmit={handleSubmit}
        >
          <p>First Name</p>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: "Input first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p>Last Name</p>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: "Input Last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p>Email</p>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p>Password</p>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Input password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </form>
      </Modal>
    </>
  );
}

export default AddUserModal;
