import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../ui/nav/Sidebar";
import { IoArrowBack } from "react-icons/io5";
import PropertyDetailsBox from "../../components/admin/PropertyDetailsBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../AuthContext/AuthContext";
import MobileAdminSidebar from "../../ui/nav/MobileAdminSidebar";

function PropertyDetails() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const paramValue = urlParams.get("id");
  const [data, setData] = useState(null);

  const { user } = useContext(AuthContext);
  useEffect(() => {
    axios
      .get(
        `https://samegbackend-production.up.railway.app/api/admin/edit-property/${paramValue}`,
        {
          headers: {
            Authorization: `token ${user}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        setData(data.data);
        console.log(data.data, 2);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <Sidebar />
      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw] px-[10px]">
        <div className="block pb-[30px] lg:hidden">
          <MobileAdminSidebar />
        </div>
        {/* <WelcomeCard text={"Here’s a quick update on your dashboard today"} /> */}

        <div
          className="flex items-center gap-[10px]"
          onClick={() => navigate("/admin/properties")}
        >
          <IoArrowBack className="text-[30px] font-[400]" />
          <p className="text-[14px] font-[400] text-neutral-350">
            back to properties
          </p>
        </div>

        <div className="flex justify-between items-center  mt-[40px] mb-[20px]">
          <p className=" text-[30px]">{data?.title}</p>
        </div>

        <div className="flex  lg:flex-nowrap gap-[30px] pb-[20px] overflow-scroll">
          <PropertyDetailsBox image={data?.image} location={data?.location} />
        </div>
        <div>
          <h3 className="text-[20px] text-neutral-700 font-[500]">
            Description
          </h3>
          <p className="text-[14px] text-neutral-400">{data?.description}</p>

          {/* <h3 className="text-[20px] text-neutral-700 font-[500] mt-[10px]">
            Price
          </h3>
          <p className="text-[14px] text-neutral-400">#1000,000</p> */}
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
