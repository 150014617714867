import clsx from "clsx";

const Container = ({ children, className }) => (
  <div
    className={clsx("mx-auto lg:max-w-[1200px] lg-[90vw] px-[20px]", className)}
  >
    {children}
  </div>
);

export default Container;
