import React from "react";
import { Link } from "react-router-dom";
import { IoGridOutline } from "react-icons/io5";
import { FaLaptopHouse } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";

function Sidebar() {
  return (
    <div className="w-[200px] min-h-[100vh] max-w-[250px] bg-secondary-50 text-neutral-350 border-r-[2px] hidden lg:block">
      <div className="fixed">
        <Link to={""} className="relative w-[200px] h-[70]">
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-[150px] z-10 lg:-top-[60px]  -top-[80px]"
          />
        </Link>

        <div className="flex flex-col gap-[20px] px-[20px] items-between justify-between h-[80vh]">
          <div className="flex flex-col gap-[20px] px-[20px]">
            <Link
              to={"/admin/dashboard"}
              className="flex items-center gap-[5px]"
            >
              <IoGridOutline className="text-neutral-400" />
              <p className="text-[14px] font-[500]text-neutral-400">
                Dashboard
              </p>
            </Link>
            <Link
              to={"/admin/properties"}
              className="flex items-center gap-[5px]"
            >
              <FaLaptopHouse className="text-neutral-300" />
              <p className="text-[14px] font-[500]text-neutral-300">
                Properties
              </p>
            </Link>
            <Link
              to={"/admin/profiles"}
              className="flex items-center gap-[5px]"
            >
              <CiUser className="text-neutral-400" />
              <p className="text-[14px] font-[500]text-neutral-400">Profiles</p>
            </Link>
          </div>

          <Link className="flex items-center gap-[5px]">
            <CiLogout className="text-neutral-400" />
            <p className="text-[14px] font-[500]text-neutral-400">Logout</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
