import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";
import Propertiestable from "../../components/tables/Propertiestable";
import AccountSidebar from "../../ui/nav/AccountSidebar";
import MobileAccountSidebar from "../../ui/nav/MobileAccountNav";
import WelcomeCard from "../../components/admin/WelcomCard";
import TextArea from "antd/es/input/TextArea";
import ContactBox from "../../components/account/ContactBox";
import { BiChart, BiChat, BiLocationPlus, BiPhone } from "react-icons/bi";

function AccountSupport() {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [componentDisabled, setComponentDisabled] = useState(true);

  return (
    <div className="bg-[#fff] flex gap-[50px] ">
      <AccountSidebar />

      <div className="flex-1 bg-[] pt-[50px] mx-auto lg:max-w-[1120px] lg-[90vw]  lg:px-[10px] px-[24px] flex flex-col ">
        <div className="block pb-[30px] lg:hidden">
          <MobileAccountSidebar />
        </div>

        <div
          className="flex items-center gap-[10px] mb-[30px]"
          onClick={() => navigate("/account/dashboard")}
        >
          <IoArrowBack className="text-[30px] font-[400]" />
          <p className="text-[14px] font-[400] text-neutral-350">
            back to Dashboard
          </p>
        </div>
        <WelcomeCard
          text={"Get in touch with us today."}
          className="max-w-[550px]"
        />

        <div className="flex flex-col lg:flex-row gap-[20px] justify-between mt-[30px]">
          <ContactBox
            buttonText="Chat to Support"
            icon={<BiChat className="text-[30px] text-secondary" />}
            text="Send an Email"
            smallText="We're here to help"
          ></ContactBox>
          <ContactBox
            buttonText="Get Directions"
            icon={<BiLocationPlus className="text-[30px] text-secondary" />}
            text=" Visit Us"
            smallText="Visit to our office"
          ></ContactBox>
          <ContactBox
            text=" Call Us"
            smallText="Mon - Fri 8am - 5pm"
            buttonText="Call Our Team"
            icon={<BiPhone className="text-[30px] text-secondary" />}
          ></ContactBox>
        </div>

        <div>
          <div className="lg:gap-[50px] mt-[40px] w-[100%]">
            <h3 className="text-[20px] text-neutral-700 font-[500] ">
              Send an instant message
            </h3>
          </div>

          <Form
            className="mt-[20px]"
            name="basic"
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div>
              <p>Full Name</p>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Input first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <p>Email Address</p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Input Last name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <p>Message</p>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Input your username!",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AccountSupport;
