import React from "react";

function ContactBox({ smallText, text, buttonText, icon }) {
  return (
    <div className="lg:w-[calc(100%/3)] w-[100%] border-[1px] border-neutral-300  p-[20px] rounded-[10px]">
      <div className="bg-secondary-50 w-[50px] flex justify-center items-center p-[10px] rounded-[10px]">
        {icon}
      </div>

      <div className="flex flex-col mt-[10px]">
        <p className="text-[16px] text-neutral-550 font-[700]">{text}</p>
        <p className="text-[14px] text-neutral-350">{smallText}</p>
      </div>
      <div className="mt-[20px]">
        <a href="" className="p-[10px] rounded-[10px] border-[1px]">
          {buttonText}
        </a>
      </div>
    </div>
  );
}

export default ContactBox;
